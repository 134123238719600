$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1500px,
);

// Mixin for responsive breakpoints
@mixin screen-($breakpoint) {
  $breakpoints: $grid-breakpoints;

  @if map-has-key($breakpoints, $breakpoint) {
    $min-width: map-get($breakpoints, $breakpoint);

    @if $min-width != 0 {
      @media (min-width: #{$min-width}) {
        @content;
      }
    } @else {
      @content;
    }
  } @else {
    @warn "Breakpoint #{$breakpoint} not found in the grid breakpoints map!";
  }
}
