@import './breakpoints.scss';
@import "./antd/override.scss";
@import "./utils.scss";
@import "./containers.scss";
@import "./modules.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

* {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  margin: 0;
}
