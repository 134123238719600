.educator-container {
  width: 100%;
  margin: auto;
  padding: 20px;

  @include screen-(xl) {
    padding: 40px 40px 0px;
  }
  @include screen-(xxl) {
    padding: 60px 80px 0px;
    max-width: 1360px;
  }
}

.educator-header-container {
  width: 100%;
  margin: auto;
  padding: 0px 20px;

  @include screen-(xl) {
    padding: 0px 40px;
  }
  @include screen-(xxl) {
    padding: 0px 80px;
    max-width: 1360px;
  }
}
