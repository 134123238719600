.ant-menu-item .ant-menu-item-icon,
.ant-menu-item span svg,
.ant-menu-submenu .ant-menu-item-icon,
.ant-menu-submenu span svg {
  height: 20px;
  fill: #818cf8;
}

.ant-menu-item:hover .ant-menu-item-icon,
.ant-menu-item:hover span svg,
.ant-menu-submenu-title:hover .ant-menu-item-icon,
.ant-menu-submenu-title:hover span svg {
  fill: #4f46e5;
}

.ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-item-selected span svg {
  fill: #4f46e5;
}
