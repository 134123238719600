.educator-dashboard-box-shadow-cards {
  box-shadow: 0px 2px 6px 0px #1018280f;
}
.full-overview-selector {
  position: absolute;
  left: 0px;
  top: -35px;
  width: 100%;
  display: flex;
  justify-content: end;
}
