.ant-layout .ant-layout-sider {
  background-color: white;
}

.ant-card .ant-card-body {
  padding: 0;
  height: 100%;
  width: 100%;
}

.ant-image .ant-image-img {
  height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.ant-image {
  height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.ant-input {
  height: 50px;
  padding: 16px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  line-height: 18px;
  background-color: white;
}

.ant-input-outlined {
  border: none;
  background-color: white;
  box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
  height: 50px;
  padding: 16px;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 18px;
}

.ant-input::placeholder {
  color: #9ca3af;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
  background-color: white;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card,
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle {
  height: 100%;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  height: inherit;
  max-height: 100%;
  width: 100%;
}

.ant-layout-header {
  padding: 0;
}

.analytics-selector {
  .ant-select-filled:not(.ant-select-customize-input) .ant-select-selector {
    background: #1a3084;
    color: #ffffff;
    padding: 20px;
  }
  .ant-select-filled:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    background: #1a3084;
    color: #ffffff;
    padding: 20px;
  }
  .ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item {
    color: white;
  }

  .ant-select-arrow {
    color: white;
  }
  .ant-select-focused.ant-select-filled:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background: #1a3084;
    color: #ffffff;
  }

  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: #1a3084;
    color: #ffffff;
    padding: 20px;
  }
}

.analyitcs-searchbox {
  .ant-input-outlined {
    border: none;
    background-color: #f3f4f6;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
    height: 50px;
    padding: 16px;
  }
}

.educator-dashboard-layout {
  .ant-layout {
    background: linear-gradient(to right, rgb(243, 244, 246) 50%, #e0e6ff 50%);
  }
}

.educator-benchmark-layout {
  .ant-layout {
    background: #e0e6ff;
  }
}
